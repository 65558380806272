import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import {
    motion,
	transform,
} from 'framer-motion'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
	const { text, image } = props;
	const { x, y } = useWindowScroll();
	const offSet = transform(y, [100, 1000], [-20, 190])

	// Render Nav Items

	const renderNav = () => {
		const navItems = [
			{
				title: 'Our Philosophy',
				link: '/our-practice#philosophy-&-values'
			},
			{
				title: 'Classes',
				link: '/what-we-offer'
			},
			{
				title: 'Pricing',
				link: '/pricing'
			},
			// {
			// 	title: 'FAQs',
			// 	link: '/our-practice#faq'
			// }
		]

		const items = navItems.map((item, i) => {
			return (
				<ListItem
					key={i}
					as={Link}
					to={item.link}
				>
					{item.title}
				</ListItem>
			)
		})

		return (
			<List>{items}</List>
		)
	}

	// Render Bendy Text

	const renderCurveText = () => {
		return (
			<Mantra>
				<CurveText width="720" height="301" viewBox="70 -60 573 301" >
					<defs>
						<path id="bend" d="M.5 1h453c39.500-1 119 10.1 119 78.5v221" fill="none" stroke="red"></path>
					</defs>
					<text>
						<textPath
							className="text-on-path"
							href="#bend"
							startOffset={offSet}
						>
							Everybody Pilates
						</textPath>
					</text>
				</CurveText>
				<SubHeading>Everyday</SubHeading>
				<Caption>(even Sunday)</Caption>
			</Mantra>
		)
	}
        
	return (
		<>
			<Container>
				<Wrap>
					{renderNav()}
					{renderCurveText()}
				</Wrap>

				<Text
					dangerouslySetInnerHTML={{__html: text}}  
				/>

				{image && (
					<Image
						key={image?.sizes?.full_width}
						src={image?.sizes?.full_width}
					>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>
				)}
			</Container>
		</>
	)
}

// Shared

const Wrap = styled.div``
const Meta = styled.div``
const Text = styled.div``
const SubHeading = styled.div``
const Caption = styled.div``

const List = styled.div``
const ListItem = styled.div``
const CurveText = styled.svg``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
	position: relative;
	/*padding: 60px 0 80px;

	${media.tablet`
		padding: 40px 0 40px;
	`}*/

	${Wrap}{
		display: flex;
		justify-content: space-between;
		
		${media.tablet`
			display:flex;
			flex-direction: column-reverse;
			padding-bottom: 40px;
		`}

		${List} {
			display: flex;
			flex-direction: column;

			${ListItem} {
				${button};
				color: ${colours.black};
				margin-bottom: 12px;

				&:visited {
					color: inherit;
				}
			}
		}
	}

	${Text} {
		max-width: 1200px;
		padding-right: 80px;
		padding-bottom: 40px;

		${media.tablet`
			padding-right: 0px;
			padding-bottom: 20px;
		`}
	}

	${BGImage} {
		width: 850px;
		height: 670px;
		margin: 0 auto;

		${media.tablet`
			width: 100%;
			height: 270px;
		`}
	}
`

const Mantra = styled.div`
	position: relative;
	max-width: 663px;
	margin-left: auto;

	${CurveText} {
		${type.heading1};
		color: ${colours.black};

		${media.tablet`
			width: 100%;
			height: auto;
		`}

		.text-on-path {
			${type.heading1};

			${media.tablet`
				font-size: 65px;
			`}
		}
	}
	${SubHeading} {
		${type.heading1};
		color: ${colours.black};
		font-family: 'Souvenir', serif;
		position: absolute;
		top: 90px;
		right: 110px;

		${media.tablet`
			top: 50px;
		`}
	}
	${Caption} {
		font-size: 21px;
		position: absolute;
		bottom: 100px;
		right: 160px;
		padding-bottom: 0;

		${media.tablet`
			font-size: 21px;
			bottom: 50px;
			right: 110px;
		`}
	}
`

export default Section
