import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { useMount } from 'react-use'
import prefetchImages from 'prefetch-image';
import { forEach } from 'lodash'

import { Block } from '../../../components'

import { media, isClient } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
    const { title, locations } = props

    const renderImages = () => {
        if (!images) return
        
        const items = images?.map((item, i) => {
            return (
                <Image
                    key={item.image?.sizes?.medium2}
                    src={item.image?.sizes?.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                image={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            )
        })

        return <List>{items}</List>
    }

    const renderLocations = () => {
        if (!locations) return

        const items = locations?.map((item, i) => {
            return (
                <Block 
                    layout={'location'}
                    className={item?.slug} 
                    key={i}
                    slug={item?.slug}
                    title={item?.title}
                    description={item?.acf?.listing_text}
                    address={item?.acf?.address}
                    display
                />
            )
        })

        return (
            <Locations>{items}</Locations>
        )
    }
        
	return (
        <Container>
            {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
            {renderLocations()}
        </Container>
	)
}

// Shared

const Wrap = styled.div``
const SubHeading = styled.div``
const Text = styled.div``
const Description = styled.div``
const Prompt = styled.div``
const Button = styled.div``
const Close = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
    background: none;
`
const Title = styled.div`
    ${type.heading1};
    padding-bottom: 44px;

    ${media.tablet`
        padding-bottom: 20px;
    `}
`

const Locations = styled.div`
    display: flex;
    gap: 24px;

    .block_location {
        flex: 0 1 50%;
    }
`

export default Section
