import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../../../styles/global'

const Section = (props) => {
    const { classes, content, title, text, link, linkText } = props
    const [activeIndex, setActiveIndex] = useState(0)

    // Render Tab Nav

    const renderTabNav = () => {
        if (!content) return null

        return content.map((item, i) => (
            <NavItem
                key={i}
                active={activeIndex === i}
                onClick={() => setActiveIndex(i)}
            >
                {item.location_name}
            </NavItem>
        ))
    }

    // Render Tab Content

    const renderTabContent = () => {
        if (!content || !content[activeIndex]) return
        const activeContent = content[activeIndex].location_classes

        return activeContent.map((item, i) => (
            <ListItem key={i}>
                {item.image && (
                    <Image
                        key={item.image?.sizes?.medium}
                        src={item.image?.sizes?.medium}
                    >
                        {(src, loading) => (
                            <BGImage
                                image={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )}
                    </Image>
                )}
                <Meta>
                    <div>
                        <Title>{item.class.post_title}</Title>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item?.short_description ? item.short_description : item.description,
                            }}
                        />
                    </div>

                    {item.link && item.link.type === 'external' && (
                        <Button as={'a'} href={item.link.url} target="_blank">
                            {item.link.title}
                        </Button>
                    )}

                    {item.link && item.link.type === 'internal' && (
                        <Button as={Link} to={item.link.url}>
                            {item.link.title}
                        </Button>
                    )}
                </Meta>
            </ListItem>
        ))
    }

    return (
        <Container>
            <Wrap>
                <Heading dangerouslySetInnerHTML={{ __html: title }} />

                <Text dangerouslySetInnerHTML={{ __html: text }} />

                {link && (
                    <Button as={Link} to={link}>
                        {linkText}
                    </Button>
                )}
            </Wrap>
            <Wrap>
                <Navigation>{renderTabNav()}</Navigation>
                <TabbedContent>{renderTabContent()}</TabbedContent>
            </Wrap>
        </Container>
    )
}

// Shared


const Heading = styled.div`
    ${type.heading1};
`

const Title = styled.div`
    ${type.heading3};
    border-bottom: 3px solid ${colours.black};
    padding-bottom: 20px;

    ${media.tablet`
        padding-bottom: 12px;    
    `}
`

const Text = styled.div`
    max-width: 80%;

    ${media.tablet`
        max-width: 100%;    
    `}

    p {
        ${type.body};
    }

    a {
        border-bottom: 1px solid;
        padding-bottom: 2px;
        color: ${colours.black};
        display: inline-block;
        width: fit-content;
        ${type.body};
    }
`

const Description = styled.div`
padding: 20px 0;

    p {
        font-size: 21px;
        margin: 0 0 12px;

        a {
            font-size: inherit;
            border-bottom: 1px solid;
            padding-bottom: 2px;
            color: ${colours.black};
            display: inline-block;

            ${media.tablet`
                font-size: 15px;    
            `}
        }

        ${media.tablet`
            font-size: 18px;    
        `}
    }
`

const Button = styled.div`
    font-size: 21px;
    color: ${colours.black};
    border-bottom: 1px solid;
    padding-bottom: 2px;
    display: inline-block;
    width: fit-content;

    ${media.tablet`
        font-size: 18px;    
    `}
`

const ListItem = styled.div`
    flex-basis: 50%;

	display: flex;
	background: ${colours.lightBeige};
	transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-radius: 22px;

	padding: 38px;
	box-sizing: border-box;
	margin-bottom: 20px;

	&:hover {
		background: ${colours.lightBlue};
	}

    ${media.tablet`
        flex-basis: 100%;
    `}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;

    width: 176px;
    height: 149px;
    margin-bottom: 42px;
    background-color: #ffffff4f;
    border-radius: 12px;

    flex: 0 1 40%;
    min-height: 238px;
    height: 100%;

    ${media.tablet`
        width: 126px;
        height: 104px;
        margin-bottom: 20px;
    `}
`
const Meta = styled.div`
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    justify-content: space-between;

    ${media.tablet`
        width: 100%;
        padding-bottom: 12px;    
        gap: 20px;
    `}
`

// Wrapper

Section.wrapper = css`
    ${media.tablet`
        margin-bottom: 0px;    
    `}
`

// Layout

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${colours.lightOrange};
    padding: 52px 40px 40px;
    border-radius: 31px;

    ${media.tablet`
        flex-wrap: wrap;
        padding: 32px 20px;
        margin-bottom: 40px;
        gap: 20px;
    `}
`

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-basis: 70%;

    ${media.tablet`
        flex-basis: 100%;
    `}
`

const Navigation = styled.div`
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    width: 100%;

    ${media.tablet`
        justify-content: flex-start;    
    `}
`

const NavItem = styled.div`
    ${button};
    background: ${colours.lightOrange};

    ${media.tablet`
        justify-content: center;
        border-color: black;
        font-size: 18px;
        padding: 12px 24px;
    `}

    &:hover {
        &:before {
            display: none;
        }
    }

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.black};
                color: ${colours.white};
                border: 3px solid ${colours.black};

                &:hover {
                    background: ${colours.black};
                }
            `
    }}
`

const ContentWrapper = styled.div`
    width: calc(66.67% - 20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${media.tablet`
        width: 100%;
    `}
`

const TabbedContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    ${media.tablet`
        flex-direction: column;    
        gap: 40px;
    `}
`

export default Section
