import React, { useState } from 'react'
import { useStaticQuery, Link } from 'gatsby'
import { useWindowScroll } from 'react-use'
import styled, { css } from 'styled-components'

import { Newsletter } from '../../components'
import { Block } from '../../components'

import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    bgImage,
    padding,
    hoverState,
    buttonSmall,
    colours,
    type,
} from '../../styles/global'
import { getGlobalData } from '../../utils'

const Footer = (props) => {
    const { data } = getGlobalData('')
    const menu = useStaticQuery(query)
    const menuData = menu.allWordpressGlobal.nodes[0].menus

    const renderPrimaryMenu = () => {
        if (!data && !menuData) return

        const mainItems = menuData.footer_menu.map((item, i) => {
            return (
                <MenuItem
                    class={'main-item-' + i}
                    key={i}
                    as={Link}
                    to={item.url}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
            )
        })

        return (
             <PrimaryMenu>{mainItems}</PrimaryMenu>
        )
    }

    const renderSocialMenu = () => {
        if (!data && !data?.sociallinks) return

        const socialItems = data.sociallinks.map((item, i) => {
            return (
                <MenuItem
                    key={i}
                    class={'social-item-' + i}
                    as={'a'}
                    href={item.link}
                    target="_blank"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
            )
        })

        return (
            <SocialMenu>
                {socialItems}
            </SocialMenu>
        )
    }

    const renderSecondaryMenu = () => {
        if (!data && !menuData) return

        const secondaryItems = menuData.footer_menu_2.map((item, i) => {
            return (
                <MenuItem
                    class={'secondary-item-' + i}
                    key={i}
                    as={Link}
                    to={item.url}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
            )
        })

        return (
            <SecondaryMenu>
                {secondaryItems}

                <Text>Follow Us</Text>
                {renderSocialMenu()}
             </SecondaryMenu>
        )
    }

    const renderLocations = () => {
        if (!data && !data?.locations) return
        
        const locations = data?.locations.map((item, i) => {
            if (!item?.title | !item?.acf?.address) return
            
            return (
                <Block 
                    layout={'location'}
                    key={i}
                    title={item?.title}
                    address={item?.acf?.address}
                    slug={item?.slug}
                    display
                />
            )
        })

        return (
            <Locations>
                {/* <Text>Our Locations</Text> */}
                <Wrap>
                    {locations}
                </Wrap>
            </Locations>
        )
    }

    const renderPhoneEmail = () => {
        if (!data && !data?.footerphone || !data?.footeremail) return

        return (
            <PhoneEmail>
                <Text
                    as={'a'}
                    href={'tel:' + data.footerphone}
                    target="_blank"
                    dangerouslySetInnerHTML={{ __html: data.footerphone }}
                />
                <Text
                    as={'a'}
                    href={'mailto:' + data.footeremail}
                    target="_blank"
                    dangerouslySetInnerHTML={{ __html: data.footeremail }}
                />
            </PhoneEmail>
        )
    }

    const renderCredit = () => {
        return (
            <Credit>
                <Text
                    as={'a'}
                    href="https://www.morebymore.com"
                    target="_blank"
                >
                    Made by More Studio
                </Text>
            </Credit>
        )
    }

    return (
        <Container>
            <Wrapper>
                <Menu>
                    {renderPrimaryMenu()}
                    {renderSecondaryMenu()}
                </Menu>
                <Wrap>
                    <Tagline
                        dangerouslySetInnerHTML={{ __html: data.footertagline }}
                    />
                    {renderLocations()}
                </Wrap>
            </Wrapper>

            <Meta>
                {renderPhoneEmail()}
                {renderCredit()}
            </Meta>

            <Newsletter
                heading={data.newsletterheading}
                text={data.newslettertext}
                footnote={data.newsletterfootnote}
                formStyles={formStyles}
            />
        </Container>
    )
}

// Shared

const Text = styled.div``
const Wrapper = styled.div``
const Logo = styled.div``
const Address = styled.div``
const Phone = styled.div``
const Email = styled.div``
const Item = styled.div``
const Wrap = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}

	width: 100%;
    display: flex;
    flex-direction: column;

    background: linear-gradient(240.03deg, #d7d7f0 31.71%, #f7f7f7 115.39%);
    padding-top: 50px;

    ${media.tablet`
		padding-top: 60px;
	`}

    ${Wrapper} {
        display: flex;

        ${media.tablet`
			flex-direction: column;
		`}
    }
`

// Menu

const Menu = styled.div`
    flex-basis: 40%;
    display: flex;
    flex-wrap: wrap;

    ${media.tablet`
		flex-basis: 100%;
		flex-direction: row;
	`}

    ${Wrapper} {
        display: flex;
        flex-direction: column;
        padding-right: 74px;

        ${media.tablet`
			padding-right: 40px;
		`}
    }
`

const MenuItem = styled.div`
    font-size: 21px;
    color: ${colours.black};
    padding-bottom: 4px;

    ${media.tablet`
		font-size: 18px;
	`}

    &:hover {
        color: ${colours.orange};
        cursor: pointer;
    }
`

const PrimaryMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    a {
        font-size: 21px;

        ${media.tablet`
            font-size: 18px;
        `}
    }
`

const SecondaryMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    a {
        font-size: 21px;

        ${media.tablet`
            font-size: 18px;
        `}
    }

    ${Text} {
        font-size: 18px;
        margin-top: 32px;
        margin-bottom: 4px;

        ${media.tablet`
            font-size: 18px;
            padding-bottom: 4px;
            margin-top: 32px;
        `}
    }
`

const SocialMenu = styled.div`
    display: flex;
    flex-direction: column;
`

// Locations

const Locations = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;

    ${Text}{
        font-size: 21px;
        line-height: 1.1;
        width: 100%;
    }

    ${Wrap} {
        display: flex;
        gap: 12px;
        width: 100%;
        flex-wrap: wrap;

        .block_location {
            flex-grow: 1;
            max-width: 48%;

            ${media.phone`
                max-width: 100%;
            `}
        }

        ${media.phone`
            flex-direction: column;
        `}
    }

    .location_card {
        background: ${colours.lightGreen};
        height: 100%;
        justify-content: space-between;

        .contents {
            padding-bottom: 0 !important;
            gap: 30px !important;
        }

        .button {
            font-size: 14px !important;
            padding: 8px 16px !important;
        }

        .address {
            font-size: 14px !important;
            opacity: 0.4;
            flex: 1;
        }
    }
`

// Tagline

const Tagline = styled.div`
    flex-basis: 60%;
    
    p {
        ${type.heading2};
    }

    ${media.tablet`
		padding: 30px 40px 40px 0;
		font-size: 30px;
	`}

    strong {
        line-height: initial;
    }
`

// Meta

const Meta = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 190px 0 24px;
    align-items: flex-end;

    ${media.tablet`
		padding-top: 0;
		flex-direction: column;
        align-items: flex-start;
	`}

    ${Wrapper} {
        display: flex;

        &:first-child {
            flex-basis: 40%;
        }

        &:last-child {
            flex-basis: 60%;
        }
    }
`

const PhoneEmail = styled.div`
    display: flex;
    flex-direction: column;
    
    ${Text} {
        font-size: 21px;
        color: ${colours.black};

        ${media.tablet`
            font-size: 14px;
        `}

        &:hover {
            color: ${colours.orange};
            cursor: pointer;
        }
    }
`

// Credit

const Credit = styled.div`
    ${Text} {
        font-size: 21px;
        color: ${colours.black};

        ${media.tablet`
            font-size: 14px;
        `}

        &:hover {
            color: ${colours.orange};
            cursor: pointer;
        }
    }
`

// Newsletter

const formStyles = css`
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;

    .dynamic-fields {
        width: 100%;
        display: flex;
        border-bottom: 1px solid black;

        ${media.tablet`
			flex-direction: column;
			align-items: center;
			padding-right: 0;
			border-bottom: 2px solid black;
		`}
    }

    .dynamic-field {
        margin-bottom: 16px;
        width: 100%;
        height: 42px;

        ${media.tablet`
			height: 32px;
		`}

        input {
            width: 100%;
            background: transparent;
            padding: 0;
            letter-spacing: 0;
            font-size: 24px;

            &::placeholder {
                letter-spacing: 0;
                font-size: 36px;
                line-height: 1.2;
                font-family: 'Roobert', sans-serif;

                ${media.tablet`
					font-size: 20px;
				`}
            }
        }

        &.email {
            margin-right: 5px;
        }
    }

    .error {
        margin-top: 32px;

        &,
        p,
        a {
            font-size: 16px;
            color: ${colours.black} !important;
        }
    }
`

// Data

export const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                menus {
                    footer_menu_2 {
                        title
                        url
                    }
                    footer_menu {
                        title
                        url
                    }
                }
            }
        }
    }
`

export default Footer
