import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { useMount } from 'react-use'
import prefetchImages from 'prefetch-image';
import { forEach } from 'lodash'

import { media, isClient } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
	const { image, tag, announcementDisplay, announcementText, announcementLink } = props;
	const [announcement, setAnnouncement] = useState(true)

	useMount(() => {
		preloadImages()

		if (isClient()) {
            if (sessionStorage.announcementSeen == 'true') {
                setAnnouncement(false)
            } 
        }
	})

	const preloadImages = () => {
		if (!image) return;
		        
        const images = [];

        forEach(image, (item) => {
            images.push(item.image?.sizes?.medium2)
        })

		prefetchImages(images)
	}

	const toggleAnnouncement = () => {
		sessionStorage.announcementSeen = true
		setAnnouncement(false)
	}

	const renderAnnouncement = () => {
		if (!announcementText || !announcementDisplay) return

		return (
			<Announcement>
				<Wrap>
					<Prompt>{announcementText}</Prompt>
					<Button as={Link} to={announcementLink}>Go</Button>
				</Wrap>
				<Close onClick={() => {toggleAnnouncement()}}>(Close)</Close>
			</Announcement>
        )
	}
        
	return (
		<>
			<Container>
				{ tag && (
					<Text>{tag}</Text>
				)}
			
				{image && (
					<Image
						key={image?.sizes?.full_width}
						src={image?.sizes?.full_width}
					>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>
				)}

				{announcement && (
					renderAnnouncement()
				)}
			</Container>
		</>
	)
}




// Shared

const Wrap = styled.div``
const Text = styled.div``
const Prompt = styled.div``
const Button = styled.div``
const Close = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
	margin: 0 -30px;
	position: relative;
	background: ${colours.lightBlue};

	${media.tablet`
		margin: 0 -16px;
	`}

	${Text} {
		${type.heading1};
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		color: ${colours.white};
		transform: translate(-50%, -50%);
		width: 100%;
	}

	${BGImage} {
		width: 100vw;
		height: 78vh;

		${media.tablet`
			width: 100%;
			background-position: top left;
		`}
	}
`

// Announcement

const Announcement = styled.div`
	background: ${colours.white};
	padding: 32px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 85px;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;

	${media.tablet`
		height: auto;
		padding: 20px;
	`}
	
	${Wrap}{
		display: flex;
		justify-content: center;
		align-items: center;

		${media.tablet`
			flex-direction: column;
		`}

		${Prompt}{
			font-size: 24px;
			margin-right: 12px;

			${media.tablet`
				margin: 0;
				text-align: center;
				font-size: 18px;
				padding-bottom: 20px;
			`}
		}

		${Button}{
			${buttonSmall};
			font-size: 24px;
			color: ${colours.white};
			background: ${colours.black};
			text-align: center;
			justify-content: center;

			${media.tablet`
				font-size: 20px;
				min-width: 90px;
			`}

			&:hover {
				color: ${colours.black};
				background: ${colours.purple};
			}
		}
	}

	${Close}{
		position: absolute;
		top: 50%;
		right: 32px;
		transform: translateY(-50%);
		font-size: 24px;
		cursor: pointer;

		${media.tablet`
			font-size: 14px;
			top: auto;
			bottom: 20px;
			transform: none;
		`}
	}
`

export default Section
