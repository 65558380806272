import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../../../styles/global'

const Section = (props) => {
    const { text, images } = props

    // Images

    const renderImages = () => {
        if (!images) return
        
        const items = images?.map((item, i) => {
            return (
                <Image
                    key={item.image?.sizes?.medium2}
                    src={item.image?.sizes?.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                image={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            )
        })

        return <List>{items}</List>
    }

    return (
        <Container>
            <Text dangerouslySetInnerHTML={{ __html: text }} />

            {renderImages()}
        </Container>
    )
}

// Shared

const Text = styled.div``
const List = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css`
    width: 100vw;
    margin: 0 -30px;
    overflow: hidden;

    ${media.tablet`
		margin: 0 -16px;
		width: 100vw;
	`}
`

// Layout

const Container = styled.div`
    background: ${colours.orange};
    min-height: 500px;
    padding: 40px;
    box-sizing: border-box;

    margin-bottom: -200px;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    ${media.tablet`
		padding: 20px;
		margin-bottom: 0;
		min-height: auto;
		background: linear-gradient(180deg, #EAAA52 24.53%, #F3F4EE 103.68%);
	`}

    &:hover {
        margin-bottom: -100px;
        background: ${colours.lightBlue};
    }

    ${Text} {
        p {
            ${type.heading2};

            a {
                font-size: inherit;
            }
        }

        ${media.tablet`
			padding-bottom: 32px;	
		`}
    }

    ${List} {
        display: flex;
        justify-content: center;
        align-items: center;

        ${media.tablet`
			margin: 0 -70px;
			padding-bottom: 40px;	
		`}

        ${BGImage} {
            width: 300px;
            height: 300px;
            transform-origin: center;

            ${media.tablet`
				width: 160px;
				height: 160px;
			`}

            &:nth-child(1) {
                transform: rotate(12deg);

                &:hover {
                    transform: rotate(0deg) translateY(-40px);
                    z-index: 100;
                }
            }
            &:nth-child(2) {
                transform: rotate(-12deg);

                &:hover {
                    transform: rotate(0deg) translateY(-40px);
                    z-index: 100;
                }
            }
            &:nth-child(3) {
                transform: rotate(16deg);

                &:hover {
                    transform: rotate(0deg) translateY(-40px);
                    z-index: 100;
                }
            }
            &:nth-child(4) {
                transform: rotate(-3deg);

                &:hover {
                    transform: rotate(0deg) translateY(-40px);
                    z-index: 100;
                }

                ${media.tablet`
					display: none;	
				`}
            }
        }
    }
`

export default Section
