import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
	const { title, slides } = props;

	const swiperParams = {
		slidesPerView: 'auto',
		freeMode: true,
		spaceBetween: 20,
        grabCursor: false,
        loop: false,
	}
	
	const renderSlides = (props) => {
        if (!slides) return

        return slides.map((item, i) => {
            return (
                <Slide key={i} as={Link} to={item.page.url}>
					<Wrap>
						<Caption>{item.page.title}</Caption>
						<Image
							key={item.image?.sizes?.medium2}
							src={item.image?.sizes?.medium2}
						>
							{(src, loading) => {
								return (
									<BGImage
										image={src}
										style={{ opacity: loading ? 0 : 1 }}
									/>
								)
							}}
						</Image>
					</Wrap>
					{item.short_description && (
						<Text
							dangerouslySetInnerHTML={{__html: item.short_description}}  
						/>
					)}
					<Button>View More</Button>
                </Slide>
            )
        })
    }
        
	return (
		<Container>
			<Title>{title}</Title>
			<Swiper {...swiperParams}>{renderSlides()}</Swiper>
		</Container>
	)
}

// Shared

const Slide = styled.div``
const Title = styled.div``
const Caption = styled.div``
const Text = styled.div``
const Button = styled.div``
const Wrap = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
    max-width: 1750px;
    margin: 0 auto;

	${Title} {
		padding-bottom: 80px;
		${type.heading2};
		text-align: center;
		color: ${colours.black};

		${media.tablet`
			padding-bottom: 40px;	
		`}
	}
	
	${Slide} {
		width: 33.33%;
		
		${media.tablet`
			width: 70%;	
		`}

		${Wrap}{
			position: relative;
			margin-bottom: 20px;

			${Caption}{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				${type.heading1};
				color: ${colours.black};
				text-align: center;
				z-index: 30;
				font-family: 'Souvenir', serif;
				width: 100%;
			}
			${BGImage}{
				width: 100%;
				min-height: 600px;

				${media.tablet`
					min-height: 370px;
				`}
			}
		}

		${Text} {
			p {
				${type.bodySmall};
				color: ${colours.black};
			}
		}

		${Button} {
			${type.bodySmall};
			color: ${colours.black};
			border-bottom: 1px solid;
			padding-bottom: 2px;
			margin-top: 20px;
			width: fit-content;
		}
	}
`

export default Section
